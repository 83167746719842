.content-center-modal {
    display: flex !important;
    justify-content: center;
    align-items: center;
    z-index: 1052;
}

.content-center-modal :global(.modal-content) {
    min-width: 23rem !important;
    padding: 1rem;
    border-radius: 3rem;
}

.modal-backdrop {
    z-index: 1051;
}