.title {
    width: 100%;
    text-align: center;
}

.container {
    width: 100%;
    margin-bottom: 15px;
}

.link-btn {
    width: '100%';
    height: 150px;
    background-color: var(--gray);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .3s;
    border-radius: 5px;
    cursor: pointer;
}

.link-btn:hover {
    filter: brightness(75%);
}

.image-container {
    position: relative;
}

.image {
    z-index: 1;
    height: 100%;
}

.delete-icon-container {
    position: absolute;
    z-index: 2;
    right: 2px;
    bottom: 2px;
    color: white;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    transition: all .3s;
    background-color: var(--blue);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
}

.delete-icon-container:hover {
    filter: brightness(75%);
}

.delete-icon {
    color: white;
}