:root {
    --blue: #193b6b;
    --orange: #ffa621;
    --gray: #e7f4ff;
}

.conotePrintModal .modal-dialog {
    min-width: 100vw !important;
}

.spinner-global-content {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 350px;
    width: 100%;
}

.receivedImgModal .modal-body{
    max-height: 90vh;
    overflow: auto;
}