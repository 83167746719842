@media screen and (min-width: 360px) {
  .img-respon {
    width: 100px;
  }

  .container-fluid-custom {
    margin-left: 2%;
    margin-right: 2%;
  }
}

@media screen and (min-width: 1920px) {
  .img-respon {
    width: 100%;
    padding: 3%;
  }

  .container-fluid-custom {
    margin-left: 3%;
    margin-right: 3%;
  }
}

@media screen and (min-width: 1366px) {
  .img-respon {
    width: 100%;
    padding: 3%;
  }

  .container-fluid-custom {
    margin-left: 3%;
    margin-right: 3%;
  }
}

@media screen and (min-width: 1366px) {
  .img-respon {
    width: 100%;
    padding: 3%;
  }

  .container-fluid-custom {
    margin-left: 3%;
    margin-right: 3%;
  }
}

@media screen and (min-width: 1280px) {
  .img-respon {
    width: 100%;
    padding: 3%;
  }

  .container-fluid-custom {
    margin-left: 3%;
    margin-right: 3%;
  }
}

@media screen and (min-width: 1024px) {
  .img-respon {
    width: 100%;
    padding: 3%;
  }

  .container-fluid-custom {
    margin-left: 3%;
    margin-right: 3%;
  }
}
