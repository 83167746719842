@import url(https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap);
.steps .step {
  display: block;
  width: 100%;
  margin-bottom: 35px;
  text-align: center;
}

.steps .step .step-icon-wrap {
  display: block;
  position: relative;
  width: 100%;
  height: 80px;
  text-align: center;
}

.steps .step .step-icon-wrap::before,
.steps .step .step-icon-wrap::after {
  display: block;
  position: absolute;
  top: 50%;
  width: 50%;
  height: 3px;
  margin-top: -1px;
  background-color: #e1e7ec;
  content: "";
  z-index: 1;
}

.steps .step .step-icon-wrap::before {
  left: 0;
}

.steps .step .step-icon-wrap::after {
  right: 0;
}

.steps .step .step-icon {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  border: 1px solid #e1e7ec;
  border-radius: 50%;
  background-color: #f5f5f5;
  color: #374250;
  font-size: 38px;
  line-height: 70px;
  z-index: 5;
}

.steps .step .step-title {
  margin-top: 16px;
  margin-bottom: 0;
  color: #110a27;
  font-size: 14px;
  font-weight: 500;
}

.steps .step:first-child .step-icon-wrap::before {
  display: none;
}

.steps .step:last-child .step-icon-wrap::after {
  display: none;
}

.steps .step.completed .step-icon-wrap::before,
.steps .step.completed .step-icon-wrap::after {
  background-color: rgb(7, 106, 27);
}

.steps .step.completed .step-icon {
  border-color: rgb(7, 106, 27);
  background-color: #ffffff;
  color: rgb(7, 106, 27);
}

.steps .step.completed.now .step-icon {
  border-color: rgb(7, 106, 27);
  background-color: rgb(7, 106, 27);
  color: rgb(255, 255, 255);
}

@media (min-width: 576px) {
  .line-ct {
    display: none;
  }
}

@media (max-width: 576px) {
  .flex-sm-nowrap .step .step-icon-wrap::before,
  .flex-sm-nowrap .step .step-icon-wrap::after {
    display: none;
  }
  .line-ct {
    background-color: rgb(255, 255, 255);
    height: 50px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
  .line-ct.completed {
    background-color: rgb(7, 106, 27);
    height: 50px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
  .steps .step {
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .flex-md-nowrap .step .step-icon-wrap::before,
  .flex-md-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}

@media (max-width: 991px) {
  .flex-lg-nowrap .step .step-icon-wrap::before,
  .flex-lg-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}

@media (max-width: 1200px) {
  .flex-xl-nowrap .step .step-icon-wrap::before,
  .flex-xl-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}

.bg-faded,
.bg-secondary {
  background-color: #f5f5f5 !important;
}

.tabs-ct {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.tab-ct {
  display: flex;
}

.tab-header-ct {
  min-width: 50%;
  padding: 15px;
  background-color: #d1d1d1;
  color: rgb(105, 105, 105);
  cursor: pointer;
  text-align: center;
}

.tab-header-disabled-ct {
  min-width: 50%;
  padding: 15px;
  background-color: #d1d1d1;
  color: rgb(105, 105, 105);
  text-align: center;
}

.tab-header-ct:hover {
  background-color: #e09107;
  color: white;
}

.tab-header-ct.active {
  color: #e09107;
  font-weight: bold;
  background-color: #ffffff;
  border-top: 5px solid #e09107;
}

.tab-contents-ct {
  padding: 1.5%;
}

.text-heard {
  color: #ffffff;
  font-size: 16px;
}
.text-spinning-ct {
  color: #110a27;
}

.step-vt {
  position: relative;
  min-height: 1em;
  color: gray;
}
.step-vt + .step-vt {
  margin-top: 1.5em;
}
.step-vt > div:first-child {
  position: static;
  height: 0;
}
.step-vt > div:not(:first-child) {
  margin-left: 1.5em;
  padding-left: 1em;
}
.step-vt.step-active-vt {
  color: #ffff;
}
.step-vt.step-active-vt .circle {
  background-color: rgb(7, 106, 27);
}

/* Circle */
.circle {
  background: gray;
  position: relative;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  box-shadow: 0 0 0 3px #fff;
}

/* Vertical Line */
.circle:after {
  content: " ";
  position: absolute;
  display: block;
  top: 1px;
  right: 50%;
  bottom: 1px;
  left: 50%;
  height: 100%;
  width: 1px;
  -webkit-transform: scale(1, 2);
          transform: scale(1, 2);
  -webkit-transform-origin: 50% -100%;
          transform-origin: 50% -100%;
  background-color: rgba(255, 255, 255, 0.25);
  z-index: -1;
}
.step-vt:last-child .circle:after {
  display: none;
}

/* Stepper Titles */
.title {
  line-height: 1.5em;
  font-weight: bold;
}
.caption {
  font-size: 0.8em;
}

@media only screen and (max-width: 10000px) {
  .img-tack {
    border: 3px solid orange;
    border-radius: 4px;
    padding: 5px;
    width: 180px;
    background-color: #ffffff;
    text-align: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
    bottom: 0;
    position: absolute;
  }
}

@media only screen and (max-width: 577px) {
  .img-tack {
    border: 3px solid orange;
    border-radius: 4px;
    padding: 5px;
    width: 200px;
    background-color: #ffffff;
    margin-top: 20px;
    text-align: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    bottom: 0;
  }
}

@media only screen and (max-width: 1002px) {
  .img-background {
    display: none;
  }
}

button.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-page.Mui-selected {
  background-color: rgb(7, 106, 27);
  color: #ffffff;
}

.style_content-center-modal__3VHRz {
    display: flex !important;
    justify-content: center;
    align-items: center;
    z-index: 1052;
}

.style_content-center-modal__3VHRz .modal-content {
    min-width: 23rem !important;
    padding: 1rem;
    border-radius: 3rem;
}

.style_modal-backdrop__2OLWg {
    z-index: 1051;
}
.MultipleImagePicker_title__2IL_i {
    width: 100%;
    text-align: center;
}

.MultipleImagePicker_container__3Yous {
    width: 100%;
    margin-bottom: 15px;
}

.MultipleImagePicker_link-btn__N5uLg {
    width: '100%';
    height: 150px;
    background-color: var(--gray);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .3s;
    border-radius: 5px;
    cursor: pointer;
}

.MultipleImagePicker_link-btn__N5uLg:hover {
    -webkit-filter: brightness(75%);
            filter: brightness(75%);
}

.MultipleImagePicker_image-container__2OcgA {
    position: relative;
}

.MultipleImagePicker_image__2_RLI {
    z-index: 1;
    height: 100%;
}

.MultipleImagePicker_delete-icon-container__287RA {
    position: absolute;
    z-index: 2;
    right: 2px;
    bottom: 2px;
    color: white;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    transition: all .3s;
    background-color: var(--blue);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
}

.MultipleImagePicker_delete-icon-container__287RA:hover {
    -webkit-filter: brightness(75%);
            filter: brightness(75%);
}

.MultipleImagePicker_delete-icon__1kI3D {
    color: white;
}
.Image_image__3ZVMC {
    width: 100%;
    height: 150px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.ExportProgressModal .modal-content{
    padding: 15px;
    border-radius: 15px;
}

@media screen and (min-width: 360px) {
  .img-respon {
    width: 100px;
  }

  .container-fluid-custom {
    margin-left: 2%;
    margin-right: 2%;
  }
}

@media screen and (min-width: 1920px) {
  .img-respon {
    width: 100%;
    padding: 3%;
  }

  .container-fluid-custom {
    margin-left: 3%;
    margin-right: 3%;
  }
}

@media screen and (min-width: 1366px) {
  .img-respon {
    width: 100%;
    padding: 3%;
  }

  .container-fluid-custom {
    margin-left: 3%;
    margin-right: 3%;
  }
}

@media screen and (min-width: 1366px) {
  .img-respon {
    width: 100%;
    padding: 3%;
  }

  .container-fluid-custom {
    margin-left: 3%;
    margin-right: 3%;
  }
}

@media screen and (min-width: 1280px) {
  .img-respon {
    width: 100%;
    padding: 3%;
  }

  .container-fluid-custom {
    margin-left: 3%;
    margin-right: 3%;
  }
}

@media screen and (min-width: 1024px) {
  .img-respon {
    width: 100%;
    padding: 3%;
  }

  .container-fluid-custom {
    margin-left: 3%;
    margin-right: 3%;
  }
}

@media (max-width: 767px) {
  #containerTop{
    display: none !important
  }
}
@media (min-width: 767px) {
  #containerBottom{
    display: none !important
  }
}

.dg-white-aliceblue {
  background-color: rgb(165, 186, 204);
}

.dg-dark-blue{
  background-color: rgb(25, 59, 107);
}

body {
  font-family: "Kanit", sans-serif !important;
  font-size: 16px !important;
}

h1 {
  font-weight: 500;
  font-size: 35;
  letter-spacing: -0.24px;
}

h2 {
  font-weight: 500;
  font-size: 29;
  letter-spacing: -0.24px;
}

h3 {
  font-weight: 500;
  font-size: 24;
  letter-spacing: -0.06px;
}
h4 {
  font-weight: 500;
  font-size: 20;
  letter-spacing: -0.06px;
}
h5 {
  font-weight: 500;
  font-size: 16;
  letter-spacing: -0.05px;
}

h6 {
  font-weight: 500;
  font-size: 14;
}

a {
  color: #000000;
  text-decoration: none;
  background-color: transparent;
}

.div-content {
  padding-top: 20px;
  padding-bottom: 40px;
}
.nav-test .active {
  border-radius: 10%;
  color: #e09107 !important;
}

.border-top-yellow {
  border-top: 3px solid #e09107;
}

.detail-ser {
  font-weight: 800 !important;
  font-size: x-large;
}

.mt-7 {
  margin-top: 3.2%;
}

.nav-test .active::after {
  content: "";
  border-bottom: 3px solid #e09107;
  width: 100%;
  display: block;
}
/*  border-bottom: 2px solid #000;*/

.nav-link {
  color: #193b6b !important;
  font-weight: 500;
  margin: 10px;
}
.nav-link:hover {
  color: #e09107 !important;
}

.nav-link.active {
  color: #e09107 !important;
  border-bottom: 3px solid #e09107 !important;
}

.mr-100 {
  margin-right: 5%;
}

.btn-primary-one {
  background-color: #e09107;
  border-color: #e09107;
  color: aliceblue;
}

.btn-primary-one:hover {
  background-color: aliceblue;
  border-color: aliceblue;
}

.btn-primary {
  background-color: #193b6b;
  border-color: #193b6b;
}

.color-blue {
  color: #193b6b;
}

.btn-primary:hover {
  background-color: #e09107;
  border-color: #e09107;
}

.color-yellow {
  color: #e09107;
}

.radius {
  padding: 15px 20px;
  border-radius: 100px;
}

.contactus .img-head-page {
  background-image: url(/static/media/head_contactus.caa48980.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-position: center top;
  padding-top: 100px;
  padding-bottom: 100px;
}

.footer-blue {
  background-color: #193b6b;
  padding: 2%;
}

.footer-detail {
  background-color: #0d2649;
  padding: 15px;
  font-size: 1em;
  text-align: center;
  color: white;
  font-weight: 200;
}

.navbar-collapse-remove-flex-grow {
  flex-grow: 0;
}

.title-footer {
  color: #e09107;
}

.footer-contact p {
  color: aliceblue;
}

.footer-contact ul li {
  color: aliceblue;
}

.contactus-detail p {
  padding-left: 35px;
}

/* About*/
.text-title-color {
  color: orange;
}
.text-title-color-front {
  color: rgb(7, 106, 27);
}
.text-subtitle {
  color: #064b28;
}
.text-front {
  color: #110a27;
  font-weight: bold;
}

.img-align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-color: #faefec;
}
.dropdown-custom.form-control.is-invalid,
.was-validated .dropdown-custom.form-control:invalid {
  background-position: 80%;
}
.dropdown-custom2.form-control.is-invalid,
.was-validated .dropdown-custom.form-control:invalid {
  background-position: 90%;
}

.card-sender {
  background-color: #ebf4ff;
  padding: 15px;
  border-radius: 5px;
}

.card-recipient {
  background-color: #ffe2e8;
  padding: 10px;
  border-radius: 5px;
}

.redius {
  background-color: white;
  padding: 5px;
  width: 20%;
  border-radius: 5px;
}

.btn-check-cm {
  background-color: aqua;
  padding: 20px;
}

.checkbox-control label {
  margin-left: 5px;
}

.checkbox-custom {
  height: 25px;
  width: 25px;
  margin-top: 5px;
}

.check-input-custom {
  height: 20px;
  width: 20px;
  margin-top: 0.2rem;
  margin-left: -1.25rem;
}

.form-check-margin label {
  margin-left: 1rem !important;
}

.form-check-margin {
  margin: 10px;
}

.logo-spac {
  flex-grow: 1;
  margin: 5px;
  margin-left: 10%;
}

.card-supplies {
  background-color: #ffffff;
  border-radius: 10px;
  margin-bottom: 10px;
  border: 2px solid #fadba3;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.my-custom-scrollbar {
  position: relative;
  height: 420px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}

.hidden-ct {
  display: none;
}

.showList {
  position: absolute;
  width: 98%;
  z-index: 1;
  overflow: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.card-custom-career:hover {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}

.card-custom-apply {
  border-top: 3px solid orange;
  transition: 0.3s;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  color: #193b6b;
}

.card-custom-apply:hover {
  color: orange;
  border-radius: 10px;
  box-shadow: 0 10px 20px 0 #d6d4d4;
}

.text-subtitle-career:hover {
  color: orange;
}

.text-subtitle-property {
  color: #000000;
}

.card-text-custom {
  margin-left: 2%;
}

.card-detail-work {
  border-radius: 10px;
  background-color: rgb(231, 244, 255);
  color: #193b6b;
}

.mt-custom {
  margin-bottom: 20px;
}

.text-subtitle-career:hover {
  color: orange;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.list-style {
  list-style: none;
}

.search {
  background: rgb(255, 255, 255)
    url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDUwIDUwIiBoZWlnaHQ9IjUwcHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA1MCA1MCIgd2lkdGg9IjUwcHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxyZWN0IGZpbGw9Im5vbmUiIGhlaWdodD0iNTAiIHdpZHRoPSI1MCIvPjxjaXJjbGUgY3g9IjIxIiBjeT0iMjAiIGZpbGw9Im5vbmUiIHI9IjE2IiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2Utd2lkdGg9IjIiLz48bGluZSBmaWxsPSJub25lIiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLXdpZHRoPSI0IiB4MT0iMzIuMjI5IiB4Mj0iNDUuNSIgeTE9IjMyLjIyOSIgeTI9IjQ1LjUiLz48L3N2Zz4=")
    left no-repeat;
  padding-right: 17px;
  background-size: 50px 20px;
}

.bg-img {
  /* The image used */
  background-image: url(/static/media/ERROR404.8b32ef47.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-size: 100%;
}

.b-center-page {
  margin-top: 40%;
}

.flex-grow-0 {
  flex-grow: 0;
}

.justifyContentbetween {
  justify-content: space-between;
}

.justifyContentCenter {
  justify-content: center;
}

.displayFlex {
  display: flex;
}

.w-50-custom {
  width: 600px;
}
.custom-lib-dropdown ::-webkit-input-placeholder {
  font-size: 1rem;
  font-weight: 400;
  color: #495057;
}
.custom-lib-dropdown ::placeholder {
  font-size: 1rem;
  font-weight: 400;
  color: #495057;
}

.custom-lib-dropdown {
  /*display: block !important;
  */
  width: 100% !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #067bf0 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
}

.custom-lib-dropdown > .react-dropdown-select-dropdown-handle > svg {
  color: #141618;
  width: 19px;
  height: auto;
  margin-bottom: 6px;
}

.custom-lib-dropdown > div > span {
  color: #141618;
}

.is-invalid-select {
  border-color: #dc3545 !important;
  border-style: solid;
  background-color: #faefec !important;
}

.is-invalid-select.form-control.is-invalid,
.was-validated .dropdown-custom.form-control:invalid {
  background-position: 86%;
}

.is-valid-select {
  border-color: #28a745 !important;
  border-style: solid;
}

.flex-rigth-filter {
  margin-left: auto;
}

.table-blue {
  background-color: #3f75c1;
}

.table-blue tr {
  color: white;
}

.table-blue-tr-td {
  background-color: white;
}

.nav-item.nav-link.active {
  background-color: #193b6b;
  color: #ffffff;
}

.nav-item.nav-link {
  background-color: #f1f1f1;
}

.fade.tab-pane.active.show {
  margin-top: -1px;
  /* background-color: #f0f6ff;*/
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  padding: 5px;
}

.nav.nav-tabs {
  border-bottom: none;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.justifyContentEnd {
  justify-content: flex-end;
}

.tabs {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.tab {
  display: flex;
}

.tab-header {
  min-width: 50%;
  padding: 15px;
  background-color: #d1d1d1;
  color: rgb(105, 105, 105);
  cursor: pointer;
  text-align: center;
}

.tab-header-disabled {
  min-width: 50%;
  padding: 15px;
  background-color: #d1d1d1;
  color: rgb(105, 105, 105);
  text-align: center;
}

.tab-header:hover {
  background-color: #e09107;
  color: white;
}

.tab-header.active {
  color: #e09107;
  font-weight: bold;
  background-color: #ffffff;
  border-top: 5px solid #e09107;
}

.tab-contents {
  background-color: white;
  padding: 1.5%;
}

.pr-1 {
  margin-right: 0px !important;
}

.stepwizard-step p {
  margin-top: 0px;
  color: #666;
}
.stepwizard-row {
  display: table-row;
}
.stepwizard {
  display: table;
  width: 100%;
  position: relative;
}
/* .stepwizard-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
} */
.stepwizard .btn.disabled,
.stepwizard .btn[disabled],
.stepwizard fieldset[disabled] .btn {
  opacity: 1 !important;
  color: #bbb;
}
.stepwizard-row:before {
  top: 14px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 100%;
  height: 1px;
  background-color: #ccc;
  z-index: 0;
}
.stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
}
.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}

/* .notification-ct {
  position: absolute;
  top: 25px;
  z-index: 1;
  color: #ffff;
  background-color: #f88e2a;
  width: 20px;
  border: 2px solid #ffffff;
  border-radius: 50px;
} */

/* .modal-dialog {

} */

.modal-content {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  border-radius: 0;
}

.error-ct {
  border-color: red;
  border-style: dotted;
  border-width: 10px;
}

.border-ct {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(255, 0, 0);
}

.is-valid-ct {
  border-color: #28a745;
  border-style: solid;
  border-width: 1px;
}

.ck-editor__editable {
  min-height: 200px;
  max-height: 200px;
}

.flex-wrap-wrap {
  flex-wrap: wrap;
}

.important-ct {
  color: red;
}

.page-A4 {
  font-size: 14px;
  width: 29.7cm;
  height: auto;
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.1cm rgba(168, 166, 166, 0.5);
}

.mg-30-ct {
  margin-left: 10px;
  margin-right: 10px;
}

.mgl-30-ct {
  margin-left: 10px;
}

.mgTop-ct {
  margin-top: 10px;
}

.mgTop-ct-20 {
  margin-top: 20px;
}

.mgTopButton-ct {
  margin-top: 15px;
  margin-bottom: 15px;
}

@media print {
  .no-print {
    display: none;
  }
}

.mgButton-ct30 {
  margin-bottom: 30px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #ffff;
}

.is-invalid:disabled,
.is-invalid[readonly] {
  background-color: rgb(250, 237, 237);
}

.nodata-show {
  color: rgb(218, 129, 129);
  font-size: 20px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: orange;
  border-color: orange;
}

.btn-primary-ct {
  background-color: orange;
  color: #fff;
}
.btn-primary-ct-front {
  background-color: rgb(7, 106, 27);
  color: #fff;
}

.btn-secondary-ct {
  background-color: #668969; 
  color: #fff;
}

.btn-secondary-front-ct {
  background-color: #f4f9e6;
  color: #fff;
}

.gradient-background {
  background: linear-gradient(90deg, rgba(68,86,101,1) 0%, rgba(105,161,148,1) 50%, rgba(228,237,243,1) 100%);
  color: #fff; /* Set the text color to make it readable */
}

.radio-size {
  width: 20px;
  height: 20px;
}

.error-invalid-feedback {
  color: #dc3545;
  font-size: 13.5px;
}

.image-border-barcode{
  border: 1px solid orange;
}
:root {
    --blue: #193b6b;
    --orange: #ffa621;
    --gray: #e7f4ff;
}

.conotePrintModal .modal-dialog {
    min-width: 100vw !important;
}

.spinner-global-content {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 350px;
    width: 100%;
}

.receivedImgModal .modal-body{
    max-height: 90vh;
    overflow: auto;
}
