.steps .step {
  display: block;
  width: 100%;
  margin-bottom: 35px;
  text-align: center;
}

.steps .step .step-icon-wrap {
  display: block;
  position: relative;
  width: 100%;
  height: 80px;
  text-align: center;
}

.steps .step .step-icon-wrap::before,
.steps .step .step-icon-wrap::after {
  display: block;
  position: absolute;
  top: 50%;
  width: 50%;
  height: 3px;
  margin-top: -1px;
  background-color: #e1e7ec;
  content: "";
  z-index: 1;
}

.steps .step .step-icon-wrap::before {
  left: 0;
}

.steps .step .step-icon-wrap::after {
  right: 0;
}

.steps .step .step-icon {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  border: 1px solid #e1e7ec;
  border-radius: 50%;
  background-color: #f5f5f5;
  color: #374250;
  font-size: 38px;
  line-height: 70px;
  z-index: 5;
}

.steps .step .step-title {
  margin-top: 16px;
  margin-bottom: 0;
  color: #110a27;
  font-size: 14px;
  font-weight: 500;
}

.steps .step:first-child .step-icon-wrap::before {
  display: none;
}

.steps .step:last-child .step-icon-wrap::after {
  display: none;
}

.steps .step.completed .step-icon-wrap::before,
.steps .step.completed .step-icon-wrap::after {
  background-color: rgb(7, 106, 27);
}

.steps .step.completed .step-icon {
  border-color: rgb(7, 106, 27);
  background-color: #ffffff;
  color: rgb(7, 106, 27);
}

.steps .step.completed.now .step-icon {
  border-color: rgb(7, 106, 27);
  background-color: rgb(7, 106, 27);
  color: rgb(255, 255, 255);
}

@media (min-width: 576px) {
  .line-ct {
    display: none;
  }
}

@media (max-width: 576px) {
  .flex-sm-nowrap .step .step-icon-wrap::before,
  .flex-sm-nowrap .step .step-icon-wrap::after {
    display: none;
  }
  .line-ct {
    background-color: rgb(255, 255, 255);
    height: 50px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
  .line-ct.completed {
    background-color: rgb(7, 106, 27);
    height: 50px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
  .steps .step {
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .flex-md-nowrap .step .step-icon-wrap::before,
  .flex-md-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}

@media (max-width: 991px) {
  .flex-lg-nowrap .step .step-icon-wrap::before,
  .flex-lg-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}

@media (max-width: 1200px) {
  .flex-xl-nowrap .step .step-icon-wrap::before,
  .flex-xl-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}

.bg-faded,
.bg-secondary {
  background-color: #f5f5f5 !important;
}

.tabs-ct {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.tab-ct {
  display: flex;
}

.tab-header-ct {
  min-width: 50%;
  padding: 15px;
  background-color: #d1d1d1;
  color: rgb(105, 105, 105);
  cursor: pointer;
  text-align: center;
}

.tab-header-disabled-ct {
  min-width: 50%;
  padding: 15px;
  background-color: #d1d1d1;
  color: rgb(105, 105, 105);
  text-align: center;
}

.tab-header-ct:hover {
  background-color: #e09107;
  color: white;
}

.tab-header-ct.active {
  color: #e09107;
  font-weight: bold;
  background-color: #ffffff;
  border-top: 5px solid #e09107;
}

.tab-contents-ct {
  padding: 1.5%;
}

.text-heard {
  color: #ffffff;
  font-size: 16px;
}
.text-spinning-ct {
  color: #110a27;
}

.step-vt {
  position: relative;
  min-height: 1em;
  color: gray;
}
.step-vt + .step-vt {
  margin-top: 1.5em;
}
.step-vt > div:first-child {
  position: static;
  height: 0;
}
.step-vt > div:not(:first-child) {
  margin-left: 1.5em;
  padding-left: 1em;
}
.step-vt.step-active-vt {
  color: #ffff;
}
.step-vt.step-active-vt .circle {
  background-color: rgb(7, 106, 27);
}

/* Circle */
.circle {
  background: gray;
  position: relative;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  box-shadow: 0 0 0 3px #fff;
}

/* Vertical Line */
.circle:after {
  content: " ";
  position: absolute;
  display: block;
  top: 1px;
  right: 50%;
  bottom: 1px;
  left: 50%;
  height: 100%;
  width: 1px;
  transform: scale(1, 2);
  transform-origin: 50% -100%;
  background-color: rgba(255, 255, 255, 0.25);
  z-index: -1;
}
.step-vt:last-child .circle:after {
  display: none;
}

/* Stepper Titles */
.title {
  line-height: 1.5em;
  font-weight: bold;
}
.caption {
  font-size: 0.8em;
}

@media only screen and (max-width: 10000px) {
  .img-tack {
    border: 3px solid orange;
    border-radius: 4px;
    padding: 5px;
    width: 180px;
    background-color: #ffffff;
    text-align: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
    bottom: 0;
    position: absolute;
  }
}

@media only screen and (max-width: 577px) {
  .img-tack {
    border: 3px solid orange;
    border-radius: 4px;
    padding: 5px;
    width: 200px;
    background-color: #ffffff;
    margin-top: 20px;
    text-align: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    bottom: 0;
  }
}

@media only screen and (max-width: 1002px) {
  .img-background {
    display: none;
  }
}

button.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-page.Mui-selected {
  background-color: rgb(7, 106, 27);
  color: #ffffff;
}
